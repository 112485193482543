import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText, SEO } from '@src/components';
import styles from './basicPage.module.scss';

const BasicPage = ({
  data: {
    page: { content, seo, title },
  },
}) => (
  <Fragment>
    <SEO seo={seo} title={title} />
    <h1 className={styles.title}>{title}</h1>
    <RichText className={styles.content} content={content} />
  </Fragment>
);

BasicPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
};

export default BasicPage;

export const query = graphql`
  query BasicPageQuery($slug: String!) {
    page: sanityBasicPage(slug: { current: { eq: $slug } }) {
      title
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      seo {
        ...SanitySeo
      }
    }
  }
`;
